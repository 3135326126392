export const submitForm = async (
  portalId: string,
  formGuid: string,
  fields: { name: string; value: string | number }[],
) => {
  if (!portalId || !formGuid) {
    console.error('Missing portalId or formId');
    return;
  }

  const hutkMatch = document.cookie.match(/hubspotutk=([^;]*);/);

  const resp = await fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      body: JSON.stringify({
        portalId,
        formGuid,
        fields,
        context: {
          pageName: document.title,
          pageUri: document.URL,
          hutk: hutkMatch?.[1],
        },
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  return resp.json();
};
